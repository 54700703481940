<template>
  <div>
    <div class="state-calculater">
      <div class="container">
        <div class="col-sm-10 mt-2 mb-5 mx-auto">
          <div class="card shadow-lg">
            <div class="card-header bg-dark text-white">State Calculator</div>
            <div class="card-body">
              <form @submit.prevent="findDeviation()">
                <div class="row">
                  <div class="col-md-2 border-right">
                    <p class="border rounded text-center m-0 py-1">A</p>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="a.a1"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="a.a2"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="a.a3"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="a.a4"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="a.a5"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 border-right">
                    <p class="border rounded text-center m-0 py-1">B</p>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="b.b1"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="b.b2"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="b.b3"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="b.b4"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="b.b5"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 border-right">
                    <p class="border rounded text-center m-0 py-1">C</p>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="c.c1"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="c.c2"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="c.c3"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="c.c4"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <input
                      required
                        v-model="c.c5"
                        type="number"
                        class="form-control"
                        placeholder="enter..."
                      />
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center my-2">
                  <div class="col-md-4 align-self-center">
                    <button class="btn btn-primary w-100">Calculate</button>
                  </div>
                </div>
              </form>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col" class="text-center">A</th>
                    <th scope="col" class="text-center">B</th>
                    <th scope="col" class="text-center">C</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Average</th>
                    <td>
                      <input
                        v-model="averageA"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="averageB"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="averageC"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Standard deviation</th>
                    <td>
                      <input
                        v-model="standardDA"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="standardDB"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="standardDC"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Power</th>
                    <td>
                      <input
                        v-model="powerA"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="powerB"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="powerC"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">Division</th>
                    <td>
                      <input
                        v-model="divisionA"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="divisionB"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="divisionC"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Covariation</th>
                    <td>
                      <input
                        v-model="covariationA"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="covariationB"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="covariationC"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Matrix</th>
                    <td>
                      <input
                        v-model="matrixA"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="matrixB"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="matrixC"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Matrix determinant</th>
                    <td>
                      <input
                        v-model="matrixDA"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="matrixDB"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="matrixDC"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Final Answer</th>
                    <td>
                      <input
                        v-model="finalA"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="finalB"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                    <td>
                      <input
                        v-model="finalC"
                        type="text"
                        readonly
                        class="form-control text-center"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CalculatorPage",
  data() {
    return {
      a:{
      a1: 4,
      a2: 3,
      a3: -2,
      a4: 5,
      a5: 6,
      },
      b:{
      b1: 7,
      b2: 4,
      b3: 5,
      b4: -3,
      b5: 6,
      },
      c:{
      c1: 3,
      c2: 5,
      c3: 4,
      c4: 6,
      c5: -1,
      },
      averageA: "",
      averageB: "",
      averageC: "",

      standardDA: "",
      standardDB: "",
      standardDC: "",

      powerA: "",
      powerB: "",
      powerC: "",

      divisionA: "",
      divisionB: "",
      divisionC: "",

      covariationA: "",
      covariationB: "",
      covariationC: "",

      matrixA: "",
      matrixB: "",
      matrixC: "",

      matrixDA: "",
      matrixDB: "",
      matrixDC: "",

      finalA: "",
      finalB: "",
      finalC: "",
    };
  },
  methods:{
    async findDeviation(){

        // Averages
        this.averageA = await this.findAverage(this.a);
        this.averageB = await this.findAverage(this.b);
        this.averageC = await this.findAverage(this.c);

        // Standard deviation=
        this.standardDA = await this.standardDeviation(this.a,this.averageA);
        this.standardDB = await this.standardDeviation(this.b,this.averageB);
        this.standardDC = await this.standardDeviation(this.c,this.averageC);

        // Power
        this.powerA = Number.parseFloat(Math.pow(this.standardDA,2).toFixed(2));
        this.powerB = Number.parseFloat(Math.pow(this.standardDB,2).toFixed(2));
        this.powerC = Number.parseFloat(Math.pow(this.standardDC,2).toFixed(2));

        // Devisions
        this.divisionA = (this.standardDA/this.averageA).toFixed(5);
        this.divisionB = (this.standardDB/this.averageB).toFixed(5);
        this.divisionC = (this.standardDC/this.averageC).toFixed(5);

        // Covariations
        this.covariationA = this.findCovariation(this.a,'a',this.b,'b',this.averageA,this.averageB);
        this.covariationB = this.findCovariation(this.a,'a',this.c,'c',this.averageA,this.averageC);
        this.covariationC = this.findCovariation(this.b,'b',this.c,'c',this.averageB,this.averageC);

        // Matrix and Matrix determinant
        this.matrixDA = this.findMatrixData('a');
        this.matrixDB = this.findMatrixData('b');
        this.matrixDC = this.findMatrixData('c');

        // Final Result
        this.finalA = ((this.matrixDB/this.matrixDA)*100).toFixed(2)+' %'; 
        this.finalB = ((this.matrixDC/this.matrixDA)*100).toFixed(2)+' %'; 
        this.finalC = (((this.matrixDA-this.matrixDB-this.matrixDC)/this.matrixDA)*100).toFixed(2)+' %'; 
        
    },

    findMatrixData(option)
    {
        if(option=='a')
        {

          let first  = Number.parseFloat(((this.powerA)+(this.powerC))-2*(this.covariationB));
          let second = Number.parseFloat(((this.powerC)+(this.covariationA))-(this.covariationB)-(this.covariationC)).toFixed(2);
          let third  = Number.parseFloat((this.powerC)+(this.covariationA)-(this.covariationB)-(this.covariationC)).toFixed(2);
          let fourth  = Number.parseFloat((this.powerB)+(this.powerC)-2*(this.covariationC)).toFixed(2);
          this.matrixA = `${first}, ${second}, ${third}, ${fourth}`;
          return Number.parseFloat(first)*Number.parseFloat(fourth)-Number.parseFloat(second)*Number.parseFloat(third);
        }
        else if(option=='b')
        {

          let first  = Number.parseFloat((this.powerC)-(this.covariationB));
          let second = Number.parseFloat((this.powerC)+(this.covariationA)-(this.covariationB)-(this.covariationC)).toFixed(2);
          let third  = Number.parseFloat((this.powerC)-(this.covariationC)).toFixed(2);
          let fourth  = Number.parseFloat((this.powerB)+(this.powerC)-2*(this.covariationC)).toFixed(2);
          this.matrixB = `${first}, ${second}, ${third}, ${fourth}`;
          return Number.parseFloat(first)*Number.parseFloat(fourth)-Number.parseFloat(second)*Number.parseFloat(third);
        }
        else if(option=='c')
        {

          let first  = Number.parseFloat(((this.powerA)+(this.powerC))-2*(this.covariationB));
          let second = Number.parseFloat(((this.powerC)-(this.covariationB))).toFixed(2);
          let third  = Number.parseFloat((this.powerC)+(this.covariationA)-(this.covariationB)-(this.covariationC)).toFixed(2);
          let fourth  = Number.parseFloat((this.powerC)-(this.covariationC)).toFixed(2);
          this.matrixC = `${first}, ${second}, ${third}, ${fourth}`;
          return Number.parseFloat(first)*Number.parseFloat(fourth)-Number.parseFloat(second)*Number.parseFloat(third);
        }
    },

    findAverage(values){
      let avg = 0;
      for(let key in values)
      {
        avg+=values[key];
      }
      return (avg/Object.keys(values).length);
    },

    standardDeviation(values,avg)
    {
      let standard_deviation = 0;
      for(let key in values)
      {
        standard_deviation+=Math.pow((values[key]-avg),2);
      }
      return Math.sqrt((standard_deviation/Object.keys(values).length)).toFixed(4);

    },

    findCovariation(value1,op1,value2,op2,firstAvg,secondAvg)
    {
      let length = Object.keys(value1).length;
      let variation = 0;
      for(let i=1; i<=length; i++)
      {
        let first  = Number.parseFloat(value1[op1+i]);
        let second = Number.parseFloat(value2[op2+i]);
        variation  += (first)*(second);
      }
      variation = ((variation/length)-(firstAvg*secondAvg)).toFixed(2);
      return Number.parseFloat(variation);
    }    
  }
};
</script>