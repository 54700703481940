<template>
    <div>
       <div class="container mt-5">
        <h1>Welcome here</h1>
        <router-link class="btn btn-success" to="calculator">Devication Calculator</router-link>
       </div>
    </div>
</template>
export default {
    name:'HomePage'
}