import {
    createRouter,
    createWebHistory
  } from 'vue-router'
    
  
  import HomePage from '@/views/HomePage'
  import CalculatorPage from '@/views/CalculatorPage'
  
  const routes = [
      {
          path: '/',
          name: 'home',
          component: HomePage
      },
      {
          path: '/calculator',
          name: 'CalculatorPage',
          component: CalculatorPage
      },
  ]
  
  
  
  
  
  
  
  
  const router = createRouter({
    history: createWebHistory(),
    routes
  })
  
  export default router